import React, { Component } from 'react';
//import loader from  '../../../assets/img/loading.svg'
import loader from  '../../../assets/img/loading.gif'

export default class Loader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <main className="animated fadeIn w-100 h-100">
            <img src={loader} style={{"position": "absolute","top": "50%","left": "50%"}}></img>
        </main>
    }

}
