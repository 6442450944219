import React, { Component, Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link, useLocation, useParams, useNavigate } from "react-router-dom";
import GroeneZorgPublicApp from './gz2/public/GroeneZorgPublicApp';
import Loader from './gz2/gedeeld/common/components/loader/Loader';


function App() {
  const Loading = () => <div>Loading ...</div>;
  const GZ2_Public = lazy(() => import('./gz2/public/GroeneZorgPublicApp'));
  const GZ2_Admin = lazy(() => import('./gz2/admin/GroeneZorgAdminApp'));
  
  return <BrowserRouter>
    <Routes >
      <Route path="/*" index element={
        <React.Suspense fallback={<Loader />}>
          <GZ2_Public />
        </React.Suspense>
      } />

      <Route path="/gz2admin/*" element={
        <React.Suspense fallback={<Loader />} >
          <GZ2_Admin  />
        </React.Suspense>
      } />

    </Routes>
  </BrowserRouter>
}

function createRouteWrapper(component: any, props?: any): any {
  //const params = useLocation();
  //const navigate = useNavigate();
  const Element: any = component;
  // No longer a CLASS; so no this
  // appManager:(this as any) product:this.allProducts['gz2.aanmelding'] 
  //return <Element {...{ ...props, match: { params }, navigate: navigate }} />
  return <Element {...props} />
}

export default App;
